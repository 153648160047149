<!--
  PACKAGE_NAME : src\pages\setting\system\menu\modal-menu.vue
  FILE_NAME : modal-menu
  AUTHOR : supark
  DATE : 2021-07-28
  DESCRIPTION : 하위 메뉴 등록 팝업 컴포넌트
-->
<template>
  <transition>
    <div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
      <div class="contents-box">
        <div class="conts-row" v-if="formData.menuDepth > 1">
          <div class="conts-box">
            <div class="conts-title">상위메뉴</div>
            <div class="contents">
              <div class="menu-hierarchy" v-for="(menu, index) in menuHierarchy" :key="menu.id">
                <span class="menu-item">{{ menu.menuNm }} </span>
                <span v-if="index < menuHierarchy.length - 1" class="separator"> > </span>
              </div>
            </div>
          </div>
        </div>
        <div class="conts-row">
          <div class="conts-box">
            <div class="conts-title">메뉴명*</div>
            <DxTextBox class="contents" v-model="formData.menuNm" styling-mode="outlined" :width="250">
              <DxValidator>
                <DxRequiredRule message="메뉴명은 필수입니다." />
              </DxValidator>
            </DxTextBox>
            <div class="limit-length-box">
              <span>{{ formData.menuNm !== null ? formData.menuNm.length : 0 }}</span
              >/{{ limitLength.menuNm }}
            </div>
          </div>
        </div>
        <div class="conts-row">
          <div class="conts-box">
            <div class="conts-title">메뉴유형*</div>
            <DxSelectBox
              placeholder="선택"
              :items="enums.common.menuType.values"
              display-expr="label"
              value-expr="value"
              v-model="formData.menuTypeCd"
              styling-mode="outlined"
              :width="250"
              :height="34"
              item-template="itemTemplate"
              class="contents"
            >
              <template #itemTemplate="{ data }">
                <div :title="getMenuTypeTooltip(data.value)">
                  {{ data.label }}
                </div>
              </template>
              <DxValidator>
                <DxRequiredRule message="메뉴유형은 필수입니다." />
              </DxValidator>
            </DxSelectBox>
          </div>
        </div>
        <div class="conts-row" v-if="formData.menuDepth === 0">
          <div class="conts-box">
            <div class="conts-title">메뉴아이콘</div>
            <DxSelectBox
              placeholder="선택"
              id="custom-templates"
              :data-source="theme.menuIcons"
              display-expr="label"
              value-expr="value"
              v-model="formData.menuIcon"
              field-template="field"
              item-template="item"
              :width="250"
              styling-mode="outlined"
              class="contents"
              :drop-down-options="{ maxHeight: 300 }"
            >
              <DxValidator>
                <DxRequiredRule message="아이콘 선택은 필수입니다." />
              </DxValidator>
              <template #field="{ data }">
                <div class="no-border-textbox" v-if="data">
                  <div style="padding-top: 3px; padding-left: 2px">
                    <img :src="data.src" width="23" height="23" />
                  </div>
                  <DxTextBox styling-mode="underlined" :value="data && data.label" :read-only="true" />
                </div>
                <div class="no-border-textbox" v-else>
                  <DxTextBox styling-mode="underlined" placeholder="선택" :read-only="true" />
                </div>
              </template>
              <template #item="{ data }">
                <div>
                  <img :src="data.src" width="23" height="23" />
                  <span style="vertical-align: middle">
                    {{ data.label }}
                  </span>
                </div>
              </template>
            </DxSelectBox>
          </div>
        </div>
        <div
          class="conts-row"
          v-if="
            formData.menuTypeCd !== enums.common.menuType.NORMAL_MENU.value
          "
        >
          <div class="conts-box">
            <div class="conts-title">Page Url</div>
            <DxTextBox placeholder="예) /esp/menu/list" class="contents" v-model="formData.pageUrl" styling-mode="outlined" :width="250"/>
            <div class="limit-length-box">
              <span>{{ formData.pageUrl && formData.pageUrl !== null ? formData.pageUrl.length : 0 }}</span
              >/{{ limitLength.pageUrl }}
            </div>
          </div>
        </div>
        <div class="conts-row">
          <div class="conts-box">
            <div class="conts-title">사용여부</div>
            <div class="conts-input">
              <DxSwitch
                class="contents"
                @value-changed="viewFlValueChange($event)"
                :value="getViewFl"
                switched-off-text="OFF"
                switched-on-text="ON"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import DxSwitch from 'devextreme-vue/switch';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import enums from '@/configs/enums';

  export default {
    components: {
      DxTextBox,
      DxValidator,
      DxRequiredRule,
      DxSwitch,
      DxSelectBox,
    },
    props: {
      contentData: Object,
      iconData: Array,
      menuList: Array,
    },
    watch: {
      formData: {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.$emit('input', filterData);
          }
        },
        deep: true,
      },
    },
    data() {
      return {
        formData: {
          menuNm: null,
          pageUrl: null,
          menuTypeCd: null,
          viewFl: 'Y',
        },
        limitLength: {
          menuNm: 50, // 메뉴명 최대 사이즈 100 byte
          pageUrl: 400, // 페이지 URL 최대 사이즈 400 byte
        },
        listData: [],
        messageData: null,
      };
    },
    computed: {
      enums() {
        return enums;
      },
      theme() {
        return this.$_theme;
      },
      getViewFl() {
        return this.formData.viewFl === 'Y';
      },
      menuHierarchy() {
        return this.generateMenuHierarchy();
      },
    },
    methods: {
      findMenuById(id) {
        return this.$store.getters.getMenuList.find(menu => menu.id === id);
      },
      generateMenuHierarchy() {
        let currentMenu = this.formData;
        const menuArray = [];

        while (currentMenu && currentMenu.menuDepth > 1) {
          currentMenu = this.findMenuById(currentMenu.parentId);
          if (currentMenu) menuArray.unshift(currentMenu);
        }
        return menuArray;
      },
      viewFlValueChange(e) {
        if (e.value) this.formData.viewFl = 'Y';
        else this.formData.viewFl = 'N';
      },
      /**
       * 메뉴 유형 툴팁 반환
       *
       * @param menuTypeCd
       * @returns {*}
       */
      getMenuTypeTooltip(menuTypeCd) {
        let tooltip = '';
        switch (menuTypeCd) {
          case this.enums.common.menuType.NORMAL_MENU.value:
            tooltip = '일반 페이지의 상위 메뉴(대메뉴/중메뉴/소메뉴)';
            break;
          case this.enums.common.menuType.LINK.value:
            tooltip = '링크 이동을 위한 메뉴';
            break;
          case this.enums.common.menuType.NORMAL_PAGE.value:
            tooltip = '일반 페이지';
            break;
          case this.enums.common.menuType.REPORT.value:
            tooltip = '보고서 관련 메뉴';
            break;
          default:
            tooltip = '';
            break;
        }
        return tooltip;
      },
    },
    mounted() {
      this.formData = { ...this.formData, ...this.contentData };
    },
  };
</script>

<style scoped>
  .contents-title-box .contents-title {
    height: 40px;
    padding-left: 5px;
    display: inline-block;
    font-size: 0.9em;
  }

  .conts-title {
    flex: 0 0 30%;
    padding-right: 10px;
    text-align: left;
  }

  .contents-box {
    width: 100%;
    padding: 20px 10px;
    background-color: #fff;
  }

  .conts-row:not(:last-child) {
    padding-bottom: 20px;
  }

  .conts-box {
    display: inline-block;
    width: 100%;
  }

  .contents {
    display: inline-block;
  }

  .conts-title {
    display: inline-block;
    vertical-align: center;
    width: 120px;
  }

  .conts-input {
    display: -webkit-inline-box;
  }

  .limit-length-box {
    padding-left: 5px;
    display: inline-block;
    font-size: 0.9em;
  }

  .menu-hierarchy {
    display: inline-flex;
  }

  .menu-item {
    margin-right: 8px;
  }

  .separator {
    margin-right: 8px;
  }

  .menu-hierarchy:last-child .menu-item {
    margin-right: 0;
  }

  .no-border-textbox {
    vertical-align: middle;
    display: flex;
  }

  #menu_config_menu_icon {
    border: 0;
  }

  #menu_config_menu_icon .dx-texteditor-input {
    font-size: 13px;
    padding-top: 10px;
    padding-left: 4px;
  }
</style>
